<template>
  <div
    class="selected-brands"
    :class="{ 'selected-brands--pb-20': selectedBrandsCount < 4 }"
  >
    <div class="flex justify-between items-center">
      <h2>Selected Brands</h2>
      <p class="font-size-14">
        {{ selectedBrandsCount }} selected brand{{
          selectedBrandsCount === 1 ? "" : "s"
        }}
      </p>
    </div>
    <a-carousel class="selected-brands__carousel">
      <div v-for="(brandGroup, index) in groupedBrands" :key="index">
        <div class="selected-brands__carousel-items-wrapper">
          <div
            v-for="(item, index) in brandGroup"
            :key="index"
            class="selected-brands__carousel-item"
          >
            <div class="selected-brands__carousel-item-image">
              <img v-if="item.logoURL" :src="item.logoURL" alt="" />
              <span class="no-image" v-else>No image</span>
            </div>

            <div class="selected-brands__carousel-item-info">
              <h3>{{ item.brands }}</h3>
            </div>
          </div>
        </div>
      </div>
    </a-carousel>
  </div>
</template>

<script>
export default {
  name: "SelectedBrands",
  components: {},
  props: {
    selectedBrands: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  computed: {
    groupedBrands() {
      if (!this.selectedBrands) {
        return [];
      }

      const selected = this.selectedBrands.filter((brand) => brand.IsSelected);

      let grouped = [];
      for (let i = 0; i < selected.length; i += 3) {
        grouped.push(selected.slice(i, i + 3));
      }
      return grouped;
    },
    selectedBrandsCount() {
      if (!this.selectedBrands) {
        return 0;
      }

      return this.selectedBrands.filter((brand) => brand.IsSelected).length;
    },
  },
};
</script>

<style lang="scss" scoped>
.selected-brands {
  padding: 20px 20px 40px;
  border-radius: 12px;
  border: 1px solid #e5e5e5;
  ::v-deep .ant-carousel {
    .slick-dots li button {
      background: var(--red);
      height: 5px;
    }
    .slick-dots-bottom {
      bottom: -20px;
    }
  }
  &--pb-20 {
    padding-bottom: 20px;
  }
  h2 {
    color: #000;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.4px;
    margin-bottom: 18px;
  }
  &__carousel {
    width: 100%;
  }
  &__carousel-items-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 14px 20px;
  }
  &__carousel-item {
    padding: 12px;
    border-radius: 10px;
    border: 1px solid #e5e5e5;
  }
  &__carousel-item-info {
    display: flex;
    flex-direction: column;
    gap: 5px;
    h3 {
      color: var(--dark);
      font-size: 16px;
      font-weight: 600;
      line-height: 100%;
      letter-spacing: -0.32px;
    }
    span {
      color: var(--dark);
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.32px;
      strong {
        font-weight: 700;
      }
    }
  }
  &__carousel-item-image {
    width: 100%;
    position: relative;
    overflow: hidden;
    padding-bottom: 51%;
    border-radius: 12px;
    border: 1px solid #e5e5e5;
    margin-bottom: 18px;
    img {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 90%;
      transform: translate(-50%, -50%);
      height: 90%;
      object-fit: contain;
    }
  }
  &__image {
    width: 68px;
    height: 52px;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 5px;
    background: white;
    border: 1px solid #ececec;
    border-radius: 3px;
    ul {
      margin: 0;
      padding: 0;
      li {
        font-size: 9px;
        position: relative;
        padding-left: 2px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        &::after {
          position: absolute;
          left: 0;
          top: 2px;
          width: 9px;
          height: 9px;
          background: #4318ff;
          border-radius: 2px;
          content: "";
        }
        svg {
          width: 5px;
          position: relative;
          z-index: 1;
          color: #fff;
          margin-right: 2px;
        }
      }
    }
  }
  &__info {
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    p {
      margin: 0;
      font-size: 13px;
      strong {
        display: block;
        line-height: 14px;
        margin-bottom: 1px;
      }
    }
  }
  &__info-cta {
    font-size: 13px;
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>

<style lang="scss">
.ant-tooltip {
  &--summary {
    max-width: 400px;
  }
}
</style>
